import React, { useState, useEffect } from 'react';
import { Box, useColorModeValue } from "@chakra-ui/react";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertToRaw, convertFromRaw, Modifier, SelectionState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const RichTextEditor = ({ initialContent, onChange }) => {
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty()
  );

  useEffect(() => {
    if (initialContent) {
      let contentState;
      try {
        // Try to parse as JSON first
        const rawContent = JSON.parse(initialContent);
        contentState = convertFromRaw(rawContent);
      } catch (error) {
        // If parsing fails, process as plain text with custom formatting
        contentState = processInitialContent(initialContent);
      }
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [initialContent]);

  const processInitialContent = (text) => {
    let contentState = ContentState.createFromText(text);
    const blockMap = contentState.getBlockMap();

    blockMap.forEach((contentBlock, key) => {
      let text = contentBlock.getText();
      let boldRanges = [];
      let start = 0;

      // Find all bold ranges in the text
      while (true) {
        const startIndex = text.indexOf('**', start);
        if (startIndex === -1) break;
        const endIndex = text.indexOf('**', startIndex + 2);
        if (endIndex === -1) break;
        boldRanges.push([startIndex, endIndex]);
        start = endIndex + 2;
      }

      // Apply bold style to each range
      boldRanges.forEach(([startIndex, endIndex]) => {
        contentState = Modifier.applyInlineStyle(
          contentState,
          SelectionState.createEmpty(key).merge({
            anchorOffset: startIndex,
            focusOffset: endIndex + 2
          }),
          'BOLD'
        );
      });

      // Remove ** markers
      boldRanges.reverse().forEach(([startIndex, endIndex]) => {
        contentState = Modifier.removeRange(
          contentState,
          SelectionState.createEmpty(key).merge({
            anchorOffset: endIndex,
            focusOffset: endIndex + 2
          }),
          'backward'
        );
        contentState = Modifier.removeRange(
          contentState,
          SelectionState.createEmpty(key).merge({
            anchorOffset: startIndex,
            focusOffset: startIndex + 2
          }),
          'backward'
        );
      });

      // Handle centered text
      if (text.startsWith('##') && text.endsWith('##')) {
        contentState = Modifier.setBlockType(
          contentState,
          SelectionState.createEmpty(key),
          'center'
        );
        contentState = Modifier.removeRange(
          contentState,
          SelectionState.createEmpty(key).merge({
            anchorOffset: text.length - 2,
            focusOffset: text.length
          }),
          'backward'
        );
        contentState = Modifier.removeRange(
          contentState,
          SelectionState.createEmpty(key).merge({
            anchorOffset: 0,
            focusOffset: 2
          }),
          'backward'
        );
      }
    });

    return contentState;
  };

  const handleEditorChange = (state) => {
    setEditorState(state);
    const content = convertToRaw(state.getCurrentContent());
    onChange(JSON.stringify(content));
  };

  const bgColor = useColorModeValue("white", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Box
      borderWidth="1px"
      borderColor={borderColor}
      borderRadius="md"
      p={2}
      bg={bgColor}
      minHeight="800px"
    >
      <Editor
	    readOnly={true}
        editorState={editorState}
        onEditorStateChange={handleEditorChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          options: ['inline', 'textAlign'],
          inline: {
            options: ['bold', 'italic', 'underline'],
          },
        }}
      />
    </Box>
  );
};

export default RichTextEditor;
