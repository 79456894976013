import React, { useState, useEffect, useRef } from 'react';
import axios from '../../../../api/index';
import {
	Box, Flex, Text, useColorModeValue, Button, VStack, Input, Radio, RadioGroup, Stack,
	useBreakpointValue, Icon, Textarea, useToast, List, ListItem, IconButton
} from "@chakra-ui/react";
import { ArrowRightIcon, CopyIcon, MinusIcon, AddIcon, ArrowUpDownIcon } from "@chakra-ui/icons";
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import { fetchCurrentUser } from '../../../../api/profile';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import Level from './components/Level';
import RichTextEditor from './components/RichTextEditor';

const BreastPathway = () => {
	const [categories, setCategories] = useState([]);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [answers, setAnswers] = useState({});
	const [prescriptionDate, setPrescriptionDate] = useState(new Date().toISOString().slice(0, 10));
	const [userProfile, setUserProfile] = useState(null);
	const [defaultProfile, setDefaultProfile] = useState(null);
	const contentRef = useRef(null);
	const [generatedText, setGeneratedText] = useState(null);
	const toast = useToast();
	const textareaRef = useRef(null);

	const [selectedOrdonnances, setSelectedOrdonnances] = useState([]);
	const [removedOrdonnances, setRemovedOrdonnances] = useState([]);
	const [selectedTreatmentPacks, setSelectedTreatmentPacks] = useState([]);
	const [removedTreatmentPacks, setRemovedTreatmentPacks] = useState([]);

	const [treatmentPackDetails, setTreatmentPackDetails] = useState({});
	const [expandedPacks, setExpandedPacks] = useState({});
	const [selectedLevels, setSelectedLevels] = useState({});

	const [nestedDict, setNestedDict] = useState(null);
	const [libelleDict, setLibelleDict] = useState(null);
	const [expandAll, setExpandAll] = useState(false);
	const [initialSelectedLevels, setInitialSelectedLevels] = useState({});

	const textColor = useColorModeValue("gray.700", "gray.200");
	const bgColor = useColorModeValue("white", "gray.700");
	const borderColor = useColorModeValue("white", "gray.600");
	const borderColorSecondary = useColorModeValue("black", "gray.300");
	const hoverColor = useColorModeValue("gray.200", "gray.300");
	const inputColor = useColorModeValue("gray.700", "#94aca4");
	const textColorSecondary = useColorModeValue("gray.500", "gray.400");

	useEffect(() => {
		fetchCurrentUser().then(data => {
		  setUserProfile(data);
		  const defaultProfile = data.profiles.find(profile => profile.default);
		  setDefaultProfile(defaultProfile || data.profiles[0]);
		  fetchCategories().then(() => {
			updateGeneratedText(answers);
		  });
		}).catch(error => {
		  console.error('Error fetching current user:', error);
		});
	  }, []);

	useEffect(() => {
		if (selectedTreatmentPacks.length > 0) {
			fetchTreatmentPackDetails();
		} else {
			setTreatmentPackDetails({});
			setExpandedPacks({});
			setSelectedLevels({});
		}
	}, [selectedTreatmentPacks]);

	const fetchTreatmentPackDetails = async () => {
		const data = { selectedTreatments: selectedTreatmentPacks };

		try {
			const response = await axios.post('/get-details-treatment-packs', data);
			const orderedDetails = selectedTreatmentPacks.map(pack => ({
				packName: pack,
				details: response.data.data[pack]
			}));
			setTreatmentPackDetails(orderedDetails);

			const initialExpandedState = {};
			const initialSelectedLevels = {};

			orderedDetails.forEach(({ packName, details }) => {
				initialExpandedState[packName] = false;

				const packDetails = details[0];
				const libelleDict = details[1];

				const setAllLevelsSelected = (obj, path) => {
					Object.keys(obj).forEach(key => {
						const fullPath = path ? `${path}>${key}` : key;
						initialSelectedLevels[fullPath] = true;
						if (typeof obj[key] === 'object') {
							setAllLevelsSelected(obj[key], fullPath);
						}
					});
				};
				setAllLevelsSelected(packDetails, packName);
			});

			setExpandedPacks(initialExpandedState);
			setSelectedLevels(initialSelectedLevels);
		} catch (error) {
			console.error('Error fetching treatment pack details:', error);
			setTreatmentPackDetails([]);
			setExpandedPacks({});
			setSelectedLevels({});
		}
	};

	const fetchCategories = async (answersToSend = null) => {
		setLoading(true);
		setError(null);
		try {
			let response;
			if (answersToSend === null) {
				response = await axios.get('/get-questions-breast-pathway');
			} else {
				response = await axios.post('/get-questions-breast-pathway', { answers: answersToSend });
			}

			let data = response.data;

			// If data is a string, try to parse it
			if (typeof data === 'string') {
				try {
					data = JSON.parse(data);
				} catch (e) {
					console.error('Error parsing response data:', e);
				}
			}

			// Check if data is an array and has at least one item
			if (Array.isArray(data) && data.length > 0) {
				setCategories(data);
				// Only update ordonnances and treatment packs if answersToSend is not an empty object
				if (answersToSend !== null && Object.keys(answersToSend).length > 0) {
					updateSelectedOrdonnances(answersToSend, data);
					updateSelectedTreatmentPacks(answersToSend);
				}
			} else {
				setError('Received invalid data from the server');
				setCategories([]);
			}
		} catch (error) {
			setError('Failed to fetch questions. Please try again.');
			setCategories([]);
		} finally {
			setLoading(false);
		}
	};

	const updateSelectedTreatmentPacks = async (currentAnswers) => {
		try {
			const response = await axios.post('/get-selected-treatment-packs', { answers: currentAnswers });
			const newSelectedTreatmentPacks = response.data.selected_treatment_packs.filter(
				pack => !removedTreatmentPacks.includes(pack)
			);
			setSelectedTreatmentPacks(newSelectedTreatmentPacks);
		} catch (error) {
			console.error('Error fetching selected treatment packs:', error);
		}
	};

	const handleInputBlur = () => {
		fetchCategories(answers);
	};

	const updateSelectedOrdonnances = async (currentAnswers, currentCategories) => {
		try {
			const response = await axios.post('/get-selected-ordonnances', { answers: currentAnswers });
			const newSelectedOrdonnances = response.data.selected_ordonnances.filter(
				ordonnance => !removedOrdonnances.includes(ordonnance)
			);
			setSelectedOrdonnances(newSelectedOrdonnances);
		} catch (error) {
			console.error('Error fetching selected ordonnances:', error);
		}
	};

	const updateGeneratedText = async (currentAnswers) => {
		const [year, month, day] = prescriptionDate.split('-');
		const formattedDate = `${day}-${month}-${year}`;

		// Restructure selectedLevels and initialSelectedLevels by treatment pack
		const selectedLevelsByPack = {};
		const initialSelectedLevelsByPack = {};

		selectedTreatmentPacks.forEach(pack => {
			selectedLevelsByPack[pack] = Object.keys(selectedLevels)
				.filter(key => key.startsWith(pack))
				.reduce((obj, key) => {
					obj[key.replace(`${pack}>`, '')] = selectedLevels[key];
					return obj;
				}, {});

			initialSelectedLevelsByPack[pack] = Object.keys(initialSelectedLevels)
				.filter(key => key.startsWith(pack))
				.reduce((obj, key) => {
					obj[key.replace(`${pack}>`, '')] = initialSelectedLevels[key];
					return obj;
				}, {});
		});

		const postData = {
		  prescriptionDate: formattedDate,
		  answers: currentAnswers,
		  selectedOrdonnances: selectedOrdonnances,
		  selectedTreatmentPacks: selectedTreatmentPacks,
		  selectedLevelsByPack: selectedLevelsByPack,
		  initialSelectedLevelsByPack: initialSelectedLevelsByPack,
		  PRES_NAME: userProfile?.user ? `${userProfile.user.firstname} ${userProfile.user.lastname}` : ''
		};

		try {
		  const textResponse = await axios.post('/generate-text-breast-pathway', postData);
		  if (typeof textResponse.data === 'object' && textResponse.data.generated_text) {
			setGeneratedText(textResponse.data.generated_text);
		  } else if (typeof textResponse.data === 'string') {
			setGeneratedText(textResponse.data);
		  } else {
			throw new Error('Unexpected response format for text generation');
		  }
		} catch (error) {
		  console.error('Error generating text:', error);
		}
	  };

	const handleAnswerChange = async (question, value) => {
		const newAnswers = {
		  ...answers,
		  [question]: value
		};
		setAnswers(newAnswers);
		await fetchCategories(newAnswers);
		await updateGeneratedText(newAnswers);
	  };

	  const handleChampLibreChange = async (question, responseIndex, value) => {
		let newAnswers;
		if (typeof responseIndex === 'string' && responseIndex.startsWith('cl-')) {
		  newAnswers = {
			...answers,
			[question]: value
		  };
		} else {
		  newAnswers = {
			...answers,
			[`${question}-champ-libre`]: value
		  };
		}
		setAnswers(newAnswers);
		await updateGeneratedText(newAnswers);
	  };

	const formatDate = (date) => {
		if (!date) return '';
		const [year, month, day] = date.split('-');
		return `${day}-${month}-${year}`;
	};

	const togglePackExpansion = (packName) => {
		setExpandedPacks(prev => ({
			...prev,
			[packName]: !prev[packName]
		}));
	};

	const toggleLevelSelection = (fullPath) => {
		setSelectedLevels(prev => ({
			...prev,
			[fullPath]: !prev[fullPath]
		}));
	};

	const handleGenerateTextAndDocuments = async () => {
		const [year, month, day] = prescriptionDate.split('-');
		const formattedDate = `${day}-${month}-${year}`;

		// Restructure selectedLevels and initialSelectedLevels by treatment pack
		const selectedLevelsByPack = {};
		const initialSelectedLevelsByPack = {};

		selectedTreatmentPacks.forEach(pack => {
			selectedLevelsByPack[pack] = Object.keys(selectedLevels)
				.filter(key => key.startsWith(pack))
				.reduce((obj, key) => {
					obj[key.replace(`${pack}>`, '')] = selectedLevels[key];
					return obj;
				}, {});

			initialSelectedLevelsByPack[pack] = Object.keys(initialSelectedLevels)
				.filter(key => key.startsWith(pack))
				.reduce((obj, key) => {
					obj[key.replace(`${pack}>`, '')] = initialSelectedLevels[key];
					return obj;
				}, {});
		});

		const postData = {
			prescriptionDate: formattedDate,
			answers: answers,
			selectedOrdonnances: selectedOrdonnances,
			selectedTreatmentPacks: selectedTreatmentPacks,
			selectedLevelsByPack: selectedLevelsByPack,
			initialSelectedLevelsByPack: initialSelectedLevelsByPack,
			PRES_NAME: `${userProfile.user.firstname} ${userProfile.user.lastname}`
		};

		try {
			// Generate text
			const textResponse = await axios.post('/generate-text-breast-pathway', postData);
			if (typeof textResponse.data === 'object' && textResponse.data.generated_text) {
				setGeneratedText(textResponse.data.generated_text);
			} else if (typeof textResponse.data === 'string') {
				setGeneratedText(textResponse.data);
			} else {
				throw new Error('Unexpected response format for text generation');
			}

			// Generate documents
			if (selectedOrdonnances.length > 0 || selectedTreatmentPacks.length > 0) {
				const docResponse = await axios.post('/generate-documents-breast-pathway', postData, { responseType: 'arraybuffer' });
				const zip = new PizZip(docResponse.data);
				const doc = new Docxtemplater().loadZip(zip);

				doc.setData({
					PRES_NAME: `${userProfile.user.firstname} ${userProfile.user.lastname}`,
					RPPS: userProfile.user.RPPS,
					DATE: formattedDate,
					HOSPITAL: defaultProfile.et_rs,
					DEPARTMENT: defaultProfile.department_name,
					TEL: defaultProfile.tel_pro,
					PLACE: defaultProfile.city,
				});

				doc.render();

				const updatedDocContent = doc.getZip().generate({ type: 'blob' });
				saveAs(updatedDocContent, 'ordo_parcours_seins.docx');
			}

			const docResponse = await axios.post('/generate-pps-breast-pathway', postData, { responseType: 'arraybuffer' });
			const zip = new PizZip(docResponse.data);
			const doc = new Docxtemplater().loadZip(zip);

			doc.setData({
				DATE: formattedDate,
				PLACE: defaultProfile.city,
			});

			doc.render();

			const updatedDocContent = doc.getZip().generate({ type: 'blob' });
			saveAs(updatedDocContent, 'pps_parcours_seins.docx');

		} catch (error) {
			console.error('Error generating text and documents:', error);
		}
	};

	const handleTextChange = (content) => {
		setGeneratedText(content);
	  };

	  const handleCopyText = () => {
		if (generatedText) {
		  try {
			// If the text is in raw format (JSON string)
			const contentState = convertFromRaw(JSON.parse(generatedText));
			let plainText = contentState.getPlainText();

			// Remove '**' and '##' markers
			plainText = plainText.replace(/\*\*/g, '').replace(/##/g, '');

			navigator.clipboard.writeText(plainText);
			toast({
			  title: "Text copied",
			  description: "The generated text has been copied to your clipboard.",
			  status: "success",
			  duration: 3000,
			  isClosable: true,
			});
		  } catch (error) {
			// If the text is still in the initial format with ** and ##
			let plainText = generatedText;

			// Remove '**' and '##' markers
			plainText = plainText.replace(/\*\*/g, '').replace(/##/g, '');

			navigator.clipboard.writeText(plainText);
			toast({
			  title: "Text copied",
			  description: "The generated text has been copied to your clipboard.",
			  status: "success",
			  duration: 3000,
			  isClosable: true,
			});
		  }
		}
	  };

	const renderQuestions = () => {
		return categories.map((category, cIndex) => (
			<Box key={cIndex} className="category-section" mb={10} pt={4} >
				<Text fontSize="lg" color={textColor} fontWeight="bold" mb="20px">
					<Icon as={ArrowRightIcon} mr="2" /> {category.category}
				</Text>
				<VStack
					align="stretch"
					spacing={6}
					pl={10}
					p={6}
					bg="#FAFAFA"
					borderWidth="1px"
					borderStyle="dashed"
					borderColor="gray.200"
					borderRadius="md">
					{/*{category.questions.map((question, qIndex) => (*/}
					{category.questions
                        .filter(question => !["Histologie", "Chirurgien.ne: Dr", "Age"].includes(question.question))
                        .map((question, qIndex) => (
						<Box key={qIndex}>
							<Text fontSize="md" fontWeight="bold" mb={2}>
								{question.question}
							</Text>
							<Box pl={8}>
								{question.responses.length > 0 ? (
									<RadioGroup
										onChange={(value) => handleAnswerChange(question.question, value)}
										value={answers[question.question] || ''}
									>
										<Stack spacing={2}>
											{question.responses.map((response, rIndex) => (
												<Box key={rIndex}>
													<Radio value={response.text}>{response.text}</Radio>
													{response.champ_libre && answers[question.question] === response.text && (
														//response.champ_libre.toLowerCase() === 'date' ? (
															<Input
																type="date"
																mt={3}
																mb={1}
																width="90%"
																onChange={(e) => handleChampLibreChange(question.question, rIndex, formatDate(e.target.value))}
																onBlur={handleInputBlur}
																value={answers[`${question.question}-champ-libre`] || ''}
															/>
														//) : (
														//	<Textarea
														//		placeholder={response.champ_libre}
														//		resize="vertical"
														//		mt={3}
														//		mb={1}
														//		width="90%"
														//		onChange={(e) => handleChampLibreChange(question.question, rIndex, e.target.value)}
														//		onBlur={handleInputBlur}
														//		value={answers[`${question.question}-champ-libre`] || ''}
														//	/>
														//)
													)}
												</Box>
											))}
										</Stack>
									</RadioGroup>
								) : null}
								{question.champ_libre_responses.map((champLibre, clIndex) => (
									champLibre.toLowerCase() === 'date' ? (
										<Input
											key={clIndex}
											type="date"
											mb={1}
											width="70%"
											onChange={(e) => handleChampLibreChange(question.question, `cl-${clIndex}`, e.target.value)}
											onBlur={handleInputBlur}
											value={answers[question.question] || ''}
										/>
									) : question.question === 'Age' ? (
										<Input
											key={clIndex}
											type="text"
											inputMode="numeric"
											pattern="[0-9]*"
											placeholder={champLibre}
											mb={1}
											width="70%"
											onKeyPress={(e) => {
												const charCode = e.which ? e.which : e.keyCode;
												if (charCode > 31 && (charCode < 48 || charCode > 57)) {
													e.preventDefault();
												}
											}}
											onChange={(e) => {
												const value = e.target.value.replace(/\D/g, '');
												if (value === '' || (parseInt(value) > 0 && parseInt(value) <= 120)) {
													handleChampLibreChange(question.question, `cl-${clIndex}`, value);
												}
											}}
											onBlur={handleInputBlur}
											value={answers[question.question] || ''}
										/>
									) : (
										<Textarea
											key={clIndex}
											placeholder={champLibre}
											resize="vertical"
											mb={1}
											width="70%"
											onChange={(e) => handleChampLibreChange(question.question, `cl-${clIndex}`, e.target.value)}
											onBlur={handleInputBlur}
											value={answers[question.question] || ''}
										/>
									)
								))}
							</Box>
						</Box>
					))}
				</VStack>
			</Box>
		));
	};

	const removeOrdonnance = (ordonnanceToRemove) => {
		setSelectedOrdonnances(prev => {
		  const newSelected = prev.filter(ordonnance => ordonnance !== ordonnanceToRemove);
		  updateGeneratedText(answers);
		  return newSelected;
		});
		setRemovedOrdonnances(prev => [...prev, ordonnanceToRemove]);
	  };

	  const addOrdonnance = (ordonnanceToAdd) => {
		setRemovedOrdonnances(prev => prev.filter(ordonnance => ordonnance !== ordonnanceToAdd));
		setSelectedOrdonnances(prev => {
		  const newSelected = [...prev, ordonnanceToAdd];
		  updateGeneratedText(answers);
		  return newSelected;
		});
	  };

	  const removeTreatmentPack = (packToRemove) => {
		setSelectedTreatmentPacks(prev => {
		  const newSelected = prev.filter(pack => pack !== packToRemove);
		  updateGeneratedText(answers);
		  return newSelected;
		});
		setRemovedTreatmentPacks(prev => [...prev, packToRemove]);
	  };

	  const addTreatmentPack = (packToAdd) => {
		setRemovedTreatmentPacks(prev => prev.filter(pack => pack !== packToAdd));
		setSelectedTreatmentPacks(prev => {
		  const newSelected = [...prev, packToAdd];
		  updateGeneratedText(answers);
		  return newSelected;
		});
	  };

	const renderSelectedOrdonnances = () => (
		<Box mt={4}>
			<Text fontSize='16px' color={textColor} fontWeight='bold' mb={2}>
				Ordonnances
			</Text>
			<List spacing={3}>
				{[...selectedOrdonnances, ...removedOrdonnances].map((ordonnance, index) => {
					const isRemoved = removedOrdonnances.includes(ordonnance);
					return (
						<ListItem key={index} display="flex" alignItems="center" color={isRemoved ? "gray.400" : "inherit"}>
							<IconButton
								icon={isRemoved ? <AddIcon /> : <MinusIcon />}
								size="xs"
								_hover={{ bg: isRemoved ? "green.500" : "red.500", color: "white" }}
								mr={2}
								cursor="pointer"
								onClick={() => isRemoved ? addOrdonnance(ordonnance) : removeOrdonnance(ordonnance)}
							/>
							{ordonnance}
						</ListItem>
					);
				})}
			</List>
		</Box>
	);

	const renderSelectedTreatmentPacks = () => (
		<Box mt={4}>
			<Text fontSize='16px' color={textColor} fontWeight='bold' mb={2} mt={2}>
				Packs traitement
			</Text>
			<List spacing={3}>
				{[...selectedTreatmentPacks, ...removedTreatmentPacks].map((pack, index) => {
					const isRemoved = removedTreatmentPacks.includes(pack);
					return (
						<ListItem key={index} display="flex" alignItems="center" color={isRemoved ? "gray.400" : "inherit"}>
							<IconButton
								icon={isRemoved ? <AddIcon /> : <MinusIcon />}
								size="xs"
								_hover={{ bg: isRemoved ? "green.500" : "red.500", color: "white" }}
								mr={2}
								cursor="pointer"
								onClick={() => isRemoved ? addTreatmentPack(pack) : removeTreatmentPack(pack)}
							/>
							{pack}
						</ListItem>
					);
				})}
			</List>
			{treatmentPackDetails.length > 0 && (
				<Box mt="30px">
					<Text fontSize="16px" color={textColor} fontWeight='bold' mb="15px">
						Détails des prescriptions pack traitement
					</Text>
					{treatmentPackDetails.map(({ packName, details }) => (
						<Box key={packName} mt="20px" bg="white" borderRadius="md">
							<Flex align="center" justify="space-between">
								<Text fontSize="16px" color={textColor} fontWeight='bold' mb="15px" ml="10px" mt="10px">
									{packName}
								</Text>
								<IconButton
									icon={<ArrowUpDownIcon />}
									size="sm"
									mr="10px"
									onClick={() => togglePackExpansion(packName)}
								/>
							</Flex>
							<VStack align="stretch" spacing={4}>
								{Object.entries(details[0]).map(([level1Key, level1Data]) => (
									<Level
										key={level1Key}
										levelKey={level1Key}
										levelData={level1Data}
										expandAll={expandedPacks[packName]}
										toggleLevelSelection={toggleLevelSelection}
										selectedLevels={selectedLevels}
										libelleDict={details[1]}
										currentPath={packName}
										packName={packName}
									/>
								))}
							</VStack>
						</Box>
					))}
				</Box>
			)}
		</Box>
	);



	const resetAll = () => {
		setAnswers({});
		setGeneratedText(null);
		setSelectedOrdonnances([]);
		setRemovedOrdonnances([]);
		setSelectedTreatmentPacks([]);
		setRemovedTreatmentPacks([]);
		setSelectedLevels({});
		setInitialSelectedLevels({});
		setExpandAll(false);
		setTreatmentPackDetails([]);
		setExpandedPacks({});
		setError(null);
		setLoading(false);
		fetchCategories({}).then(() => {
		  updateGeneratedText({});
		});
	  };

	return (
		<Box ml="-10px" mt="-10px" mb={{ sm: "20px", md: "20px", xl: "20px" }} borderRadius='15px' px='0px' display='flex' flexDirection='column' justifyContent='center' backgroundColor="transparent" align='left'>
			<Flex direction={useBreakpointValue({ base: "column", md: "row" })} width="100%" justifyContent="space-between">
				{/* Left column */}
				<Flex direction="column" flex="1" border="2px solid" boxShadow="0px 3px 7px rgba(0, 0, 0, 0.09)" borderColor={borderColor} bg={bgColor} p='24px' borderRadius='20px'>
					<Text fontSize={{ sm: "lg", lg: "xl" }} color={textColor} fontWeight='bold' mb="20px">
						Parcours cancer du sein intégré
					</Text>
					<Text fontSize={{ sm: "sm", lg: "sm" }} color={textColorSecondary} mb="20px">
						Ce module permet d'organiser le parcours de soins d'un cancer du sein localisé avec génération du compte-rendu de consultation, des ordonnances, du PPS et de la fiche de liaison HDJ. Nous recommendons de remplir l'ensemble des questions.
					</Text>
					<Box position="relative" flex="1" display="flex" flexDirection="column">
						{/*{renderStepper()}*/}
						<Box flex="1" overflowY="auto" ref={contentRef}>
							{renderQuestions()}
						</Box>
					</Box>
				</Flex>
				{/* Right column */}
				<Flex direction="column" flex="1" maxWidth={{ base: "100%", md: "50%" }} ml={{ base: "0", md: "20px" }} mt={{ base: "20px", md: "0" }} border="2px solid" borderColor={borderColorSecondary} bg="transparent" p='24px' borderRadius='20px'>
					<Text fontSize='16px' color={textColor} fontWeight='bold'>
						Date
					</Text>
					<Input
						type="date"
						value={prescriptionDate}
						onChange={(e) => setPrescriptionDate(e.target.value)}
						borderRadius="10px"
						_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "1px" }}
						width="70%"
						mb="10px"
						mt="10px"
						bg="white"
					/>
					{(selectedOrdonnances.length > 0 || removedOrdonnances.length > 0) && renderSelectedOrdonnances()}
					{(selectedTreatmentPacks.length > 0 || removedTreatmentPacks.length > 0) && renderSelectedTreatmentPacks()}
					{generatedText && (
						<Box mt="20px">
							<Text fontSize='16px' color={textColor} fontWeight='bold' mb="10px" mt="15px">
								Consultation
							</Text>
							<RichTextEditor
								initialContent={generatedText}
								onChange={handleTextChange}
							/>
							<Button
								leftIcon={<CopyIcon />}
								onClick={handleCopyText}
								mt="10px"
							>
								Copier le texte
							</Button>
						</Box>
					)}
					<Button
						onClick={handleGenerateTextAndDocuments}
						mt="40px"
						borderWidth="2px"
						backgroundColor="white"
						color="black"
						borderRadius="10px"
						borderColor="black"
						_hover={{ backgroundColor: hoverColor }}
						boxShadow="0px 5px 7px rgba(0, 0, 0, 0.09)"
						_focus={{ borderColor: inputColor, boxShadow: "0 0 0 1px", borderWidth: "2px" }}>
						Imprimer ordonnances, PPS et fiche de liaison
					</Button>
					<Button
						onClick={resetAll}
						mt="20px"
						borderRadius="10px">
						Réinitialiser les choix
					</Button>
				</Flex>
			</Flex>
		</Box>
	);
};

export default BreastPathway;
